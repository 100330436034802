export const CountLinenUrl = {
  main: '/count-linen',
  cartAndClosets: '/count-linen/carts-and-closets/',
  linenRoom: '/count-linen/linen-room/',
};

export const CountLinenPageID = {
  main: 'main',
  cartAndClosets: 'carts-and-closets',
  linenRoom: 'linen-room',
};

export const CountLinenNav = {
  mainNav: [
    {
      label: 'Carts & Closets',
      url: CountLinenUrl.cartAndClosets,
      permission: 'canViewCountLinenOrders',
    },
    {
      label: 'Linen Room',
      url: CountLinenUrl.linenRoom,
      permission: 'canViewCountLinenLinenRoom',
    },
  ],
};

export const tableConfig = {
  [CountLinenPageID.linenRoom]: {
    headers: {
      products: [
        'ID',
        'Product Name',
        'Par',
        'To Orders',
        'From Orders',
        'On Hand',
        'Required',
        'From Laundry',
      ],
    },
    columns: {
      RcID: { type: 'text', label: 'ID' },
      ItemDesc: { type: 'text', label: 'Product Name' },
      StckQnty: { type: 'text', label: 'Par' },
      RetnQnty: { type: 'text', label: 'To Orders' },
      LnrmQnty: { type: 'text', label: 'From Orders' },
      OhndQnty: { type: 'textInput', inputType: 'number', label: 'On Hand' },
      RqrdQnty: { type: 'textInput', inputType: 'number', label: 'Required' },
      LdryQnty: { type: 'textInput', inputType: 'number', label: 'From Laundry' },
    },
  },
  [CountLinenPageID.cartAndClosets]: {
    headers: {
      products: [
        'ID',
        'Product Name',
        'Par',
        'On Hand',
        'Required',
        'Stocked',
        'Emergency',
        'Returned',
      ],
    },
    columns: {
      RcID: { type: 'text', label: 'ID' },
      OrdrDesc: { type: 'text', label: 'Order Name' },
      UsefTaly: { type: 'checkBox', label: 'Counted' },
      HhldTime: { type: 'text', label: 'By Hand-held' },
      CensQnty: { type: 'text', label: 'Census' },
    },
    columnsRight: {
      RcID: { type: 'text', label: 'ID' },
      ItemDesc: { type: 'text', label: 'Product Name' },
      StckQnty: { type: 'textInput', label: 'Par' },
      OhndQnty: { type: 'textInput', label: 'On Hand' },
      RqrdQnty: { type: 'textInput', label: "Reqr'd" },
      LdryQnty: { type: 'textInput', label: "Stck'd" },
      LnrmQnty: { type: 'textInput', label: 'Emerg' },
      RetnQnty: { type: 'textInput', label: "Retrn'd" },
    },
  },
};

export const searchKeys = {
  order: [
    { id: 'OrdrDesc', name: 'Order Name' },
    { id: 'HhldTime', name: 'By Hand-Held' },
    { id: 'CensQnty', name: 'Census' },
  ],
  deliveryitem: [
    { id: 'ItemDesc', name: 'Product Name' },
    { id: 'StckQnty', name: 'Par' },
    { id: 'OhndQnty', name: 'On Hand' },
    { id: 'RqrdQnty', name: 'Required' },
    { id: 'LdryQnty', name: 'Stocked' },
    { id: 'LnrmQnty', name: 'Emergency' },
    { id: 'RetnQnty', name: 'Returned' },
  ],
  linenroom: [
    { id: 'ItemDesc', name: 'Product Name' },
    { id: 'StckQnty', name: 'Par' },
    { id: 'RetnQnty', name: 'To Orders' },
    { id: 'LnrmQnty', name: 'From Orders' },
    { id: 'OhndQnty', name: 'On Hand' },
    { id: 'RqrdQnty', name: 'Required' },
    { id: 'LdryQnty', name: 'From Laundry' },
  ],
};

export const Labels = {
  [CountLinenPageID.cartAndClosets]: {
    pageTitle: 'Count Linen Orders',
  },
  [CountLinenPageID.linenRoom]: {
    pageTitle: 'Count Linen Room',
  },
};
export const Links = {
  [CountLinenPageID.cartAndClosets]: {
    help: 'https://linen-helper-help-documents.s3.amazonaws.com/CountLinen/CountLinenOrders.pdf',
  },
  [CountLinenPageID.linenRoom]: {
    help: 'https://linen-helper-help-documents.s3.amazonaws.com/CountLinen/CountLinenRoom.pdf',
  },
};

export const Messages = {
  unSaveChanges: 'You have some unsaved changes that will be lost. Are you sure you want to leave?',
};

export default CountLinenUrl;
